declare const numeral;
export const UpGestao = {
    version: '0.0.1',
    loading: false,
    newOrderStarted: false,
    newOrder: {
        client: null,
        address: null,
        product: null,
        items: []
    },
    resetOrder: () => {
        UpGestao.newOrder = {
            client: null,
            address: null,
            product: null,
            items: []
        }
    }
}


export function getValorProduto(objClientePedido, vlrProduto) {

  vlrProduto = numeral(numeral(vlrProduto).format('0.0000')).value();

    if (objClientePedido && vlrProduto > 0) {
        let objTipoCadastro = objClientePedido.cad_tipo_cadastro;
        if (objTipoCadastro) {
            if (objTipoCadastro.par_v01) {
                let vlrPercentual = (vlrProduto * numeral(numeral(objTipoCadastro.par_v01).format('0.00')).value()) / 100;
                let valor = (vlrProduto + vlrPercentual);
                console.log('valor',valor);
                return valor;
            }
        }

        return numeral(vlrProduto).format('0.0000');
    }

    return 0;
}

export function getPesquisa(objeto, strComplemento) {

    let arrFiltro = [];
    let strFiltro = '';

    for (let k in objeto) {
        if (objeto[k] || objeto[k] === 0) {
            if (typeof (objeto[k]) === 'object') {
                if (objeto[k].length) {
                    arrFiltro.push(k + ':' + objeto[k].join('|'))
                }
            } else {
                arrFiltro.push(k + ':' + objeto[k]);
            }
        }
    }

    if (arrFiltro.length) {
        strFiltro = '?q=(' + arrFiltro.join(',') + ')';
    }

    if (strComplemento != '' && strComplemento != undefined) {
        strFiltro = (strFiltro === '') ? strComplemento : strFiltro + '&' + strComplemento;
    }

    return strFiltro;
}



export function formatarData(data) {

    let arrDataOne = data.split(' ')[0];
    let arrDataTwo = arrDataOne.split('-');

    return (arrDataTwo[2] + '/' + arrDataTwo[1] + '/' + arrDataTwo[0]);
}


export function formatarPreco(valor) {

    if (valor === null)
        return `0,00`;

    valor = `${valor}`.replace('.', ',');
    if (valor.lastIndexOf(',') === 0)
        valor = `0${valor}`;

    valor = (valor.lastIndexOf(',') < 0) ? `${valor},00` : `${valor}00`.substr(0, valor.lastIndexOf(',') + 3);

    return valor;
}


export function extrairFormaPgto(formaPgto) {

    if (formaPgto) {

        let retorno = formaPgto.split('-');
        return parseInt(retorno[0].trim());
    }

    return null;
}


export function getNatureza(idNatureza) {

    switch (idNatureza) {
        case Constantes.NATUREZAS.VENDA:
            return 'venda';
        case Constantes.NATUREZAS.ORCAMENTO:
            return 'orcamento';
        default:
            return 'venda';
    }
}


export const Constantes = {
    PARCELAMENTO_VISTA: 1,
    PARCELAMENTO_PRAZO: 2,
    PARCELAMENTOS: ['1x', '2x', '3x', '4x', '5x', '6x', '7x', '8x', '9x', '10x', '11x', '12x', '13x', '14x', '15x', '16x', '17x', '18x', '19x', '20x', '21x', '22x', '23x', '24x'],
    TIPOS_PRODUCAO: ['Própria', 'Terceiros'],
    FORMAS_PAGAMENTO: ['Dinheiro', 'Boleto Bancário', 'Cartão'], /* Deprecated */
    NATUREZAS: {
        VENDA: 1,
        COMPRA: 2,
        DEVOLUCAO_VENDA: 3,
        DEVOLUCAO_COMPRA: 4,
        OUTRAS_SAIDAS: 5,
        OUTRAS_ENTRADAS: 6,
        ORCAMENTO: 11,
        SAIDA_INSUMOS: 15,
        ENTRADA_PRODUCAO: 16,
        PLANOS: 17,
        PRESTACAO_SERVICO: 31,
        TRANSPORTE: 57
    }
};

export function ajustaStrPreco(strPreco: string){
  const lastTwoDigits = strPreco.slice(-2); // Pegar os dois últimos dígitos
  const lastOneDigit = strPreco.slice(-1); // Pegar os dois últimos dígitos
  if (lastTwoDigits === '00') {
    return strPreco.slice(0, -2); // Remover os dois últimos zeros
  } else if(lastOneDigit == '0'){
    return strPreco.slice(0, -1); // Remover o ultimo zero
  }

    return strPreco; // Retornar o valor original se os dois últimos dígitos não forem 00

}
